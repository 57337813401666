import React, { useState }  from 'react'
import Img from 'gatsby-image'
import VisibilitySensor from "react-visibility-sensor";
import { animated, useSpring } from "react-spring";
import "../styles/components/hero.scss"

export default ({ data, type }) => {

  var date = ""
  if (data.date){
    var thisYear = new Date().getFullYear();
    date = data.date.includes(thisYear) ? data.date.split(',')[0] : data.date;
  }

  const [isVisible, setVisibility] = useState(false);

  const animatedProps = useSpring({
    transform: isVisible ? `translateY(0px)` : `translateY(150px)`,
    opacity: isVisible ? 1 : 0,
  })

  const onVisibleChange = (isVisible) =>  {
    isVisible && setVisibility(isVisible)
  }

  return (
    <div className="hero">
      <div className="hero-wrapper">
        <div className="hero-particles">
        </div>
        <div className="hero-details">
          <h3 className="hero-headline">{data.headline}</h3>
          {
            data.subhead &&
            <p className="hero-subhead">{data.subhead}</p>
          }
          {
            data.date &&
            <div className="hero-post_info">
              <span>{date}</span>
              <span>{data.readTime} min read</span>
            </div>
          }
        </div>
      </div>
      {
        data.heroImage &&
        <VisibilitySensor partialVisibility onChange={onVisibleChange}>
          <animated.div style={animatedProps}  className="hero-image">
            <Img alt="" fluid={data.heroImage.fluid} />
          </animated.div>
        </VisibilitySensor>
      }
    </div>
  )
}
