import React from 'react'
import { Link } from 'gatsby'
import '../styles/components/footer.scss'

const Footer = (props)  =>{
  
  return (
    <footer className="site-footer">
      <div className="footer-wrapper">
        <div className="footer-logo">
          <div className="screen-reader-text">Momihood</div>
          <Link to="/" className="logo"></Link>
        </div>
        <div className="copywrite">&copy; {new Date().getFullYear()}, Momihood, all rights reserved.</div>
      </div>
    </footer>
  )
}

export default Footer