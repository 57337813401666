import React from 'react'
import { Link } from 'gatsby'
import '../styles/components/navigation.scss';

export default () => (
  <nav role="navigation" className="navigation">
    <div className="navigation-container">
      <h1>
        <div className="screen-reader-text">Momihood</div>
        <Link to="/" className="logo"></Link>
      </h1>
      {/* <ul className="nav-menu">
        <li className="navigationItem">
          <Link to="/">Home</Link>
        </li>
        <li className="navigationItem">
          <Link to="/blog/">Blog</Link>
        </li>
      </ul> */}
    </div>
  </nav>
)
